body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* restyle scrollbar across all the app */
::-webkit-scrollbar {
  width: 8px;
  /* change color */
}

/* change color of scrollbar */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}
