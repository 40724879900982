.LogForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-width: 350px;
    width: 100%;
}

.LogForm input {
    padding: 8px;
    border-radius: 5px;
    margin-bottom: 10px;
    max-width: 330px;
    width: 100%;
    border-color: #9FDEEC;
}

.LogForm input:focus-visible {
    outline-color: #9FDEEC;
}

.Logo img {
    max-width: 200px;
}

.container {
    height: 100vh;
    display: flex;
    background-color: #F8F9F9;
}

.Login {
    position: absolute;
    width: 280px;
}

.forgotpswd a {
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    color: #666
}

.forgotpswd {
    padding-top: 10px;
}

a:hover {
    text-decoration: underline;
}


.params-card {
    width: auto;
    display: flex;
    margin: auto 15px;
}