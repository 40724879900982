:root {
  --card-row-height: 4em;
  --card-width: 100%;
}

.home-div {
  display: flex;
  flex-wrap: wrap;
}

.home-card {
  width: var(--card-width);
  padding: 15px 0 20px 0;
}

.home-card-row {
  height: var(--card-row-height);
}

.home-card-row-disabled {
  height: var(--card-row-height);
  color: lightgrey;
  cursor: not-allowed;
}

.home-card-2rows {
  height: calc(var(--card-row-height) * 3 / 2);
}

.home-card-header {
  background: linear-gradient(60deg, #84c2ce, #96dcea);
  color: white;
  font-weight: 600;
  /* box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.3); */
  border-radius: 15px 15px 0px 0px;
}

.home-card-header-disabled {
  background: lightgrey;
  color: white;
  font-weight: 600;
  /* box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.3); */
  border-radius: 15px 15px 0px 0px;
}

.home-card-content:not(:last-child) {
  border-bottom: 1px solid rgb(207, 207, 207);
}

.home-card-content {
  cursor: pointer;
  display: flex;
  padding: 5px;
}

.home-card-content-disabled {
  cursor: not-allowed;
  display: flex;
  padding: 5px;
}

.home-card-title-row {
  font-size: 0.8rem;
  font-weight: 600;
  max-width: fit-content;
  padding: 0 !important;
  margin-right: 10px;
  margin-left: 5px;
}
.home-card-title-row-disabled {
  cursor: not-allowed;
  font-size: 0.8rem;
  font-weight: 600;
  max-width: fit-content;
  padding: 0 !important;
  margin-right: 10px;
  margin-left: 5px;
}

.logo:hover {
  text-decoration: none;
}

.home-card-content-chip {
  font-size: 0.85rem !important;
  background-color: rgb(252, 165, 33);
  color: white;
  border-radius: 8px;
  padding: 3px 7px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  width: auto;
}

.home-card-content-chip-disabled {
  font-size: 0.85rem !important;
  background-color: lightgrey;
  color: white;
  border-radius: 8px;
  padding: 3px 7px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  width: auto;
}

.home-card-content-text {
  font-size: 0.8rem !important;
  color: grey;
}

@media (max-width: 600px) {
  .home-div {
    margin-top: 48px;
  }
}

@media (max-width: 768px) {
  .home-div {
    flex-direction: column;
    align-items: center;
  }

  .home-card {
    padding: 10px;
  }

  .home-card-header {
    font-size: 0.75rem !important;
    display: flex !important;
  }

  .home-card-title-row {
    font-size: 0.75rem;
  }

  .home-card-content-chip {
    font-size: 0.75rem !important;
    padding: 1px;
    margin-right: 3px !important;
  }

  .home-card-content-text {
    font-size: 0.75rem !important;
  }
}

.div-pdf-viewer {
  margin: 10px 40px;
  height: calc(100vh - 146px);
}

.toolbar-input {
  background-color: white;
  opacity: 0.3;
  height: 35px;
  border-radius: 8px;
  border: none;
  margin-right: 10px;
  padding: 10px 10px 10px 30px;
  font-size: 15px;
}

.img-maker-patient {
  width: 80px;
  margin-left: 5px;
}

.MuiDataGrid-row:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.04);
}

.MuiDataGrid-row:hover {
  background-color: rgba(0, 0, 0, 0.12) !important;
}
