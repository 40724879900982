.css-1bqbtb9-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #84c2ce !important;
}

.button-group-box {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.group-button {
  background-color: #84c2ce !important;
  color: white !important;
  margin: 0 10px !important;
}

.group-button-success {
  background-color: limegreen !important;
  color: white !important;
  margin: 0 10px !important;
}

.group-button:hover,
.group-button-success:hover,
.btn-back-pdf:hover {
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%),
    0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%) !important;
}

.group-button-selected {
  background-color: rgb(67, 136, 148) !important;
  color: white !important;
  margin: 0 10px !important;
  cursor: default !important;
}

.btn-alert-traitment {
  width: 32px !important;
  height: 32px !important;
  border-radius: 50% !important;
  min-width: 0 !important;
}

.btn-alert-traitment:hover {
  background-color: rgb(67, 136, 148, 0.15) !important;
}

.btn-back-pdf {
  background-color: #84c2ce !important;
  color: white !important;
  margin: 0 40px !important;
  margin-top: 10px;
}

.button-alert-pdf {
  text-align: center;
  margin: 5px 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  padding: 7px;
}

.button-alert-pdf:hover {
  opacity: 0.9;
  background-color: rgb(67, 136, 148, 0.15);
  border-radius: 8px;
}

.cancel-button:hover {
  background-color: #438894 !important;
}

.css-1gvrmk7-MuiButtonBase-root-MuiListItemButton-root:hover {
  background-color: rgb(135, 198, 210, 0.8) !important;
}

.button-patient-info {
  background-color: white !important;
  color: #84c2ce !important;
  display: flex !important;
  align-items: center !important;
  padding: 8px 16px !important;
}

.button-patient-info:hover {
  background-color: white !important;
  filter: brightness(0.95) !important;
}

.button-base {
  background-color: #84c2ce !important;
  color: white !important;
  display: flex !important;
  align-items: center !important;
  padding: 8px 16px !important;
}

.button-base:hover {
  filter: brightness(0.95) !important;
}

.button-base:disabled {
  filter: brightness(0.8) !important;
}
