.pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Roboto';
}

.pagination-button {
    background: #fafbfd;
    border: 0.6px solid #d5d5d5;
    border-radius: 8px;
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 14px;
    color: #757575;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 3px;
    min-width: 35px;
    height: 30px;
    cursor: pointer;
    padding: 0 5px;
}

.pagination-button-active {
    cursor: pointer;
    background: #84c2ce;
    border: none;
    border-radius: 8px;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: bold;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 3px;
    min-width: 40px;
    height: 35px;
    padding: 0 5px;
}

.pagination-button:disabled {
    cursor: default;
    background: #fafbfd;
    border: 0.6px solid #d5d5d5;
    border-radius: 8px;
    font-family: 'Roboto';
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #757575;
    opacity: 0.3;
    text-align: center;
    width: 35px;
    height: 30px;
}

.test-pagination option {
    padding: 5px;
}