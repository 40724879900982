.sidebar-close {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1050;
  max-width: 65px;
  background-color: #fff;
  border-right: 1px solid #ececec;
  transition: ease-in-out;
  transition-duration: 0.2s;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1050;
  max-width: 255px;
  background-color: #fff;
  border-right: 1px solid #ececec;
  transition: ease-in-out;
  transition-duration: 0.2s;
}

.navbar-close {
  left: 55px !important;
  background-color: #fff !important;
  transition: ease-in-out;
  transition-duration: 0.2s;
}

.navbar {
  left: 255px;
  background-color: #fff !important;
  transition: ease-in-out;
  transition-duration: 0.2s;
}

.nav-link {
  color: #000d5b !important;
}

.nav-link:hover {
  color: #641abf;
}

.active {
  background: #641abf0e;
  border-radius: 6px;
  color: #641abf !important;
  right: 0%;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
}

.queries-close {
  position: absolute;
  right: 0;
  left: 65px;
  top: 85px;
  transition: ease-in-out;
  transition-duration: 0.2s;
}

@media (max-width: 576px) {
  .queries-close {
    left: 0;
  }

  .navbar-close {
    left: 0 !important;
  }
}

.queries {
  position: absolute;
  right: 0;
  left: 255px;
  top: 75px;
  transition: ease-in-out;
  transition-duration: 0.2s;
}

.no-queries {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
}

@media (max-width: 991.98px) {
  .sidebar {
    max-width: 65px;
  }
  .navbar {
    left: 65px;
  }
  .queries {
    left: 65px;
  }
  .dd-profile {
    margin-right: 3rem;
  }
}

@media (max-width: 575.98px) {
  .navbar {
    left: 0px;
  }
  .queries {
    left: 0px;
  }
  .dd-profile {
    margin-right: 0rem;
  }
}

.navbar-left {
  left: 0px !important;
  background-color: #fff !important;
  transition: ease-in-out;
  transition-duration: 0.2s;
}

.navbar-side-menu {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: bolder;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #000d5b;
}

.active .navbar-side-menu {
  color: #641abf;
}

.dropdown-item {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: bolder;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #000d5b;
}

.dropdown-toggle {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #404040 !important;
}

.navbar-nav {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: bolder;
  font-size: 14px;
  letter-spacing: 0.3px;
}
