.MuiDataGrid-columnHeaders {
  background: linear-gradient(60deg, #84c2ce, #96dcea);
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  color: white;
}

.MuiDataGrid-row:nth-child(odd) {
  background-color: #fff;
}

.MuiDataGrid-row:nth-child(even) {
  background-color: #eceff7;
}

.Mui-selected {
  color: #fff !important;
  position: relative;
  border-bottom: none;
  background: 0 0;
  z-index: 3 !important;
  border-radius: 3px !important;

  height: fit-content;
  box-shadow: 0 4px 20px #00000024, 0 7px 10px -5px rgba(#96dcea, 65%);
  background: linear-gradient(60deg, #84c2ce, #96dcea);
}

.MuiTab-root {
  background-color: '#fff';
  margin: -2px 15px 0 !important;
  border-radius: 5px 5px 0px 0px !important;
  margin-right: 8px !important;
  max-width: 400px !important;
  padding: 12px 16px !important;
  min-height: 48px;
}




/* check color  */
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #84c2ce !important
}

.alert-comment {
  width: 95%;
  margin: 10px auto;
  padding: 5px 20px;
  background-color: #84c2ce30;
  border-radius: 10px;
}