.sidebar {
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 260px;
  background-color: #fff;
  box-shadow: 0 16px 38px -12px #0000008f, 0 4px 25px #0000001f,
    0 8px 10px -5px #0003;
}

.header-logo {
  display: flex;
  align-items: center;
}

.logo {
  padding: 15px 0;
  margin: 0;
  display: flex;
  position: relative;
  z-index: 4;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}

.header-logo a {
  padding: 5px 0;
  font-size: 18px;
  color: #3c4858;
  white-space: nowrap;
  font-weight: 400;
  line-height: 30px;
  overflow: hidden;
  text-align: center;
  display: block;
  text-decoration: none;
}

.logo span {
  color: #ffa726;
  font-weight: 700;
}

.sidebar-content {
  position: relative;
  height: calc(100vh - 75px);
  overflow: auto;
  width: 260px;
  z-index: 4;
  padding-bottom: 30px;
}

.sidebar-list {
  margin-top: 20px;
  display: block;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-item {
  display: list-item;
  text-decoration: none;
  list-style: none;
}

.nav-item .false {
  cursor: default;
  opacity: 0.3;
  pointer-events: none;
}
